<template>
    <div>
        <div class="modal_header mb-4">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple">
                        <div class="title_h">Показать</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <!-- <el-button type="success">
                                Сохранить и закрыть
                            </el-button> -->
                            <el-button> Закрыть </el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="molad-content p-3">
            <div
                class="op-an mb-3"
                style="margin-top: -20px; font-size: 15px; font-weight: 600"
            >
                Услуги: Opshe analish
            </div>
            <table class="table table-bordered table-hover table-layout_fixed">
                <tr>
                    <th>Наименование</th>
                    <th>Количество</th>
                </tr>
                <tr>
                    <td>Pahta</td>
                    <td>5</td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>